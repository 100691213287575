// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-en-js": () => import("./../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-hi-en-js": () => import("./../../src/pages/hi.en.js" /* webpackChunkName: "component---src-pages-hi-en-js" */),
  "component---src-pages-hi-js": () => import("./../../src/pages/hi.js" /* webpackChunkName: "component---src-pages-hi-js" */),
  "component---src-pages-index-en-js": () => import("./../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policies-js": () => import("./../../src/pages/policies.js" /* webpackChunkName: "component---src-pages-policies-js" */),
  "component---src-pages-tos-js": () => import("./../../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */),
  "component---src-templates-blog-list-js": () => import("./../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

